import { useFsFlag } from "@flagship.io/react-sdk";
import { Grid } from "@boxt/design-system";

import PaymentDetails from "@Components/FinanceCalculator/components/PaymentDetails";
import type { PageType } from "@Components/FinanceCalculator/types";
import { FinancePaymentTypes } from "@Components/FinanceCalculator/types";
import type { ButterFinanceTextTabFields, LifeRepExample } from "@Components/pages/finance/types";

import { LeftColumnDiv, RightColumnDiv } from "../../styles";

import LeftColumnContent from "./components/LeftColumnContent";
import RightColumnContent from "./components/RightColumnContent";

export type Props = {
  content: ButterFinanceTextTabFields;
  onAddToBasket?: () => void;
  onProductDetailsClick?: () => void;
  pageType?: PageType;
};

const TextTab = ({ content, onAddToBasket, onProductDetailsClick, pageType = "finance" }: Props) => {
  const isHsFinanceActive = useFsFlag("hs-finance", false).getValue();

  const {
    monthly_payment,
    total_installation_cost,
    total_servicing_cost,
    cost_of_finance,
    total_repayable,
    annaul_interest_rate,
    apr,
    term_length,
  } = content;

  const activePlan: LifeRepExample = {
    apr: annaul_interest_rate,
    calculations: {
      creditBorrowed: 0,
      deposit: 0,
      monthlyPayment: monthly_payment,
      priceOfGoods: total_installation_cost,
      servicingCosts: total_servicing_cost,
      totalInterestPaid: cost_of_finance,
      totalRepayableAmount: total_repayable,
    },
    deferredPeriod: 0,
    repApr: apr,
    term: term_length,
  };

  return (
    <>
      <Grid.Col as={LeftColumnDiv} lg={9}>
        <LeftColumnContent content={content} />
      </Grid.Col>
      <Grid.Col as={RightColumnDiv} lg={7}>
        <RightColumnContent
          content={content}
          onAddToBasket={onAddToBasket}
          onProductDetailsClick={onProductDetailsClick}
          pageType={pageType}
        />
      </Grid.Col>
      {isHsFinanceActive ? (
        <PaymentDetails
          activePlan={activePlan as LifeRepExample}
          content={content}
          hasFixedAmount
          isPdpRedirectEnabled={false}
          onAddToBasket={onAddToBasket}
          onProductDetailsClick={onProductDetailsClick}
          paymentType={FinancePaymentTypes.Life}
          showAddToBasketCta={false}
        />
      ) : null}
    </>
  );
};

export default TextTab;
