import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/compat/router";
import { isEmpty } from "lodash-es";
import useSWR from "swr";

import formatQuestion from "@Helpers/screener/formatQuestion";
import getQuestionLocalePath from "@Helpers/screener/getQuestionLocalePath";
import type { Products } from "@Collections/products";
import { ProductTypeToCategory } from "@Collections/products";
import { API_URLS, LANDING_URLS } from "@Collections/routes";
import type { Event } from "@StateMachines/Screener";
import { Question, Template } from "@StateMachines/Screener";
import { fetcher } from "@DataAccess/local";
import { labels, ReactGAEvent, screenerCategory } from "@Lib/googleAnalytics";
import { useAnalytics } from "@Hooks/useAnalytics";
import useChat from "@Hooks/useChat";
import { screenerQueryMap } from "@Pages/api/_next/screeners/_constants";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import { answerQuestion, undoAnswer } from "./dataAccess";
import type { FallbackDataType } from "./types";

const useScreener = ({
  fallbackData,
  variant,
  initialValues,
  getCompleteUrl,
  startPage,
  productType,
}: {
  fallbackData: FallbackDataType;
  variant: ScreenerVariant;
  initialValues?: Record<string, string>;
  getCompleteUrl: (persistedData) => string;
  startPage: string;
  productType?: Products;
}) => {
  const [isFinished, setIsFinished] = useState(false);
  const [isAnswering, setIsAnswering] = useState(false);

  const router = useRouter();
  const url = `${API_URLS.SCREENING}?variant=${variant}`;
  const { data, mutate, isValidating, error } = useSWR(url, fetcher, {
    fallbackData,
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });
  const { update, onShow } = useChat();
  const segmentAnalytics = useAnalytics();

  const { isFirstQuestion } = data;

  const onBack = useCallback(() => {
    if (isFirstQuestion) {
      router?.push(`${startPage}${window.location.search}`);
    } else {
      mutate(undoAnswer(url), false);
    }
  }, [isFirstQuestion, startPage, mutate, router, url]);

  const onAnswer = useCallback(
    async (ques: Question, value: string | null, event: Event) => {
      setIsAnswering(true);

      const label = value || labels.skip;

      ReactGAEvent({
        action: ques,
        category: screenerCategory[variant],
        label,
      });

      if (segmentAnalytics) {
        segmentAnalytics?.track("screener_question_completed", {
          category: productType && ProductTypeToCategory[productType],
          screener_answer: label,
          screener_question: ques,
        });
      }

      // make the api call to answer a question
      const { isCompleted, persistedData, ...nextData } = await answerQuestion(url, ques, value, event);

      // send screening values as custom attrs
      onShow(() => update(nextData.context));

      if (nextData.template === Template.Redirect && nextData.redirectPath) {
        return await router?.push(nextData.redirectPath);
      }

      if (isCompleted) {
        setIsFinished(true);
        // check from the results of the update if it is completed

        await router?.push(`${getCompleteUrl(persistedData)}${window.location.search}`);
      } else {
        // if not yet completed set the results (next question) as the data
        await mutate(nextData, false);
        setIsAnswering(false);
      }
    },
    [mutate, router, url, variant, segmentAnalytics, productType, getCompleteUrl, onShow, update],
  );

  const question = useMemo(() => formatQuestion(data.question), [data]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    // Filter out postcode from answers
    const answerHistory = Object.keys(data.context).reduce((object, key) => {
      const obj = object;
      if (key !== "postcode" && data.context[key]) {
        obj[key] = data.context[key];
      }
      return obj;
    }, {});

    window.dataLayer.push({
      event: `screener_${question}`,
      formLocation: `screener_${variant}`,
      ...(!isEmpty(answerHistory) && answerHistory),
    });
  }, [question, data.context, variant]);

  useEffect(() => {
    if (segmentAnalytics && question) {
      segmentAnalytics?.track("screener_question_viewed", {
        category: productType && ProductTypeToCategory[productType],
        screener_question: question.key,
      });
    }
  }, [segmentAnalytics, question, productType]);

  useEffect(() => {
    const isPostcodeAtTheEndOfaScreener = [
      ScreenerVariant.BoilerInstallationExperiment,
      ScreenerVariant.BoilerInstallation,
      ScreenerVariant.BoilerSubscription,
    ].includes(variant);
    const questionsToRedirect = isPostcodeAtTheEndOfaScreener
      ? [Question.OrderDetails]
      : [Question.Postcode, Question.OrderDetails];
    if (questionsToRedirect.includes(fallbackData.question as Question)) {
      router?.push(LANDING_URLS[variant] + window.location.search);
    }
  }, [fallbackData.question, router, variant]);

  const initialValue = useMemo(
    () =>
      question?.key && initialValues?.[screenerQueryMap[question?.key]]
        ? initialValues[screenerQueryMap[question?.key]]
        : "",
    [initialValues, question],
  );

  const questionParentKey = question?.key.split(".")[0];

  return {
    answerId: `${variant}-${question?.key}-answer`,
    answers: data?.answers,
    assetCollectionName: data?.assetCollectionName,
    commonIcon: data?.commonIcon,
    context: data?.context,
    defaultVisibleItemCount: data?.defaultVisibleItemCount,
    extraInfoComponent: data?.extraInfoComponent,
    hasAdditionalInfo: data?.hasAdditionalInfo,
    hasHelpText: data?.hasHelpText,
    initialValue,
    isAnswering,
    isFinished,
    isFirstQuestion: data?.isFirstQuestion,
    isLoading: (!data && !error) || !question || isValidating,
    localeKey: data?.localeKey,
    onAnswer,
    onBack,
    price: data?.price,
    progress: data.progress,
    question: question?.key,
    questionId: `${variant}-${question?.key}`,
    questionLocalePath: getQuestionLocalePath({
      question: questionParentKey,
      questionSuffix: data.context?.[data.questionSuffixKey],
      template: data.template,
    }),
    questionParentKey,
    questionTimeSignature: question?.timeSignature,
    styleConfig: data?.styleConfig,
    template: data?.template,
    validations: data?.validations,
  };
};

export default useScreener;
