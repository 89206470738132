import { useFsFlag } from "@flagship.io/react-sdk";

import { Products } from "@Collections/products";
export type Props = {
  productType: Products | undefined;
};

type ReturnType = {
  hasFinance: Boolean;
};

const useHasFinance = ({ productType }: Props): ReturnType => {
  const isSolarFinanceAvailable = useFsFlag("is_solar_finance_available", false).getValue();

  const hasFinance = () => {
    if (productType === Products.Solar) {
      return isSolarFinanceAvailable;
    }
    if (productType === Products.BoilerRental) return false;

    return true;
  };

  return {
    hasFinance: hasFinance(),
  };
};

export default useHasFinance;
