if (!global.Intl) {
  global.Intl = require("intl");
  require("intl/locale-data/jsonp/en");
}

const penceToPounds = (priceInPence: number) => priceInPence / 100;
const poundsToPence = (priceInPounds: number) => priceInPounds * 100;

const currencyFormatter = (
  priceInPence: number | undefined | null,
  quantity = 1,
  precision = 2,
  stripDoubleZeros = true,
): string => {
  const formatter = new Intl.NumberFormat("en", {
    currency: "GBP",
    minimumFractionDigits: precision,
    style: "currency",
  });

  const priceInPounds = penceToPounds(Number(priceInPence) * quantity);
  const formattedPrice = formatter.format(priceInPounds);
  return stripDoubleZeros ? formattedPrice.replace(/\D00$/, "") : formattedPrice;
};

export { penceToPounds, poundsToPence };
export default currencyFormatter;
