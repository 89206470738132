import { isEmpty } from "lodash-es";
import { H5, Paragraph, Spacing } from "@boxt/design-system";

import PaymentLogos from "@Components/Checkout/stages/components/PaymentMethods/Stripe/components/Logos";
import Image from "@Components/Image";
import { StyledHr } from "@Components/layout";
import ButterHTMLParsingContent from "@Components/pages/common/ButterHTMLParsingContent";
import ParsedBulletPoints from "@Components/pages/common/HTMLParser/ParsedBulletPoints";
import type { ButterFinanceTextTabFields } from "@Components/pages/finance/types";

import { BulletImageContainer, ImageContainer, SubText, SubTitleOne, TitleContainer } from "./styles";

const componentMap = {
  p: {
    styledAs: SubText,
  },
};

type Props = {
  content: ButterFinanceTextTabFields;
};

const LeftColumnContent = ({ content }: Props) => {
  const {
    text_tab_left_column_title: title,
    text_tab_left_column_top_subtitle: topSubtitle,
    text_tab_left_column_top_description: topDescription,
    text_tab_left_column_payment_logos: paymentLogos,
    text_tab_left_column_bullets: bullets,
    text_tab_left_column_bullets_icon: bulletsIcon,
    text_tab_left_column_image: image,
    text_tab_left_column_image_alt: imageAlt,
    text_tab_left_column_bottom_subtitle: bottomSubtitle,
    text_tab_left_column_bottom_description: bottomDescription,
    text_tab_right_column_amount_value: amountValue,
    subscription_tab: isSubscriptionTab,
  } = content;

  const mappedBulletItems = (bullets || []).map(({ text }, index) => ({
    icon: <Image alt="" height={20} src={bulletsIcon as string} width={20} />,
    item: text,
    key: String(index),
  }));

  const hasImage = !isEmpty(image) && !isEmpty(imageAlt);

  return (
    <>
      {title && (
        <TitleContainer>
          <H5 bottom={3}>{title}</H5>
          {!isSubscriptionTab && (
            <Paragraph boxtTheme="coalDark" weight="regular">
              {amountValue}
            </Paragraph>
          )}
        </TitleContainer>
      )}
      {isSubscriptionTab && (
        <Spacing mv={2}>
          <StyledHr data-testid="horizontal-rule" />
        </Spacing>
      )}
      {topSubtitle && (
        <Spacing mb={topDescription ? 2 : 4} mv={4}>
          <SubTitleOne>{topSubtitle}</SubTitleOne>
        </Spacing>
      )}
      {topDescription && (
        <Spacing mb={1} mt={topSubtitle ? 2 : 4}>
          <ButterHTMLParsingContent
            addContainerSpacing={false}
            componentMap={componentMap}
            fields={{ content: topDescription }}
            idAttribute="text-tab-left-column-top-description"
          />
        </Spacing>
      )}
      {!isEmpty(paymentLogos) && (
        <Spacing data-testid="payment-logos" mb={4} mt={1}>
          <PaymentLogos icons={paymentLogos} logoWrapperSpacing={{ mb: { md: 0, sm: 2 }, ml: { sm: 0 } }} />
        </Spacing>
      )}

      {isSubscriptionTab && (
        <Spacing mb={2}>
          <Image alt={imageAlt} height={50} objectFit="contain" src={image as string} width={140} />
        </Spacing>
      )}
      <Spacing mb={{ lg: 3, sm: 2, xlg: 4 }}>
        <BulletImageContainer $hasImage={hasImage}>
          {mappedBulletItems.length > 0 && (
            <ParsedBulletPoints
              data-testid="text-tab-bullet-list"
              idAttribute="text-tab-bullet-list"
              items={mappedBulletItems}
              theme="slate"
            />
          )}
          {hasImage && (
            <ImageContainer data-testid="text-tab-left-column-image">
              {!isSubscriptionTab && <Image alt={imageAlt} height={200} src={image as string} width={200} />}
            </ImageContainer>
          )}
        </BulletImageContainer>
      </Spacing>
      {(bottomSubtitle || bottomDescription) && (
        <Spacing mb={1}>
          <StyledHr data-testid="horizontal-rule" />
        </Spacing>
      )}
      {bottomSubtitle && (
        <Spacing mb={2} mt={3}>
          <H5>{bottomSubtitle}</H5>
        </Spacing>
      )}
      {bottomDescription && (
        <Spacing mv={2}>
          <ButterHTMLParsingContent
            addContainerSpacing={false}
            componentMap={componentMap}
            fields={{ content: bottomDescription }}
            idAttribute="text-tab-left-column-bottom-description"
          />
        </Spacing>
      )}
    </>
  );
};

export default LeftColumnContent;
